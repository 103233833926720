import Vue from "vue";
import Element from 'element-ui'
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;
Vue.use(Element, {
  size: 'medium' // set element-ui default size
})
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
